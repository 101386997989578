<template>
  <div>
    <div
      class="
        uk-section uk-padding-remove-vertical uk-margin-remove-vertical
        in-equity-breadcrumb
      "
    >
      <div class="uk-container">
        <div class="uk-grid">
          <div class="uk-width-1-1">
            <ul class="uk-breadcrumb">
              <li>
                <router-link :to="{ name: 'guest.home' }">Home</router-link>
              </li>
              <li><span>Privacy Policy</span></li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div
      class="
        uk-section uk-padding-remove-vertical uk-margin-remove-vertical
      "
    >
      <div class="uk-container uk-margin-top uk-background-default uk-padding">
        <privacy-policy />
      </div>
    </div>

  </div>
</template>

<script>
import PrivacyPolicy from '../shared/PrivacyPolicy.vue';

export default {
  components: {
    PrivacyPolicy,
  },
};
</script>
