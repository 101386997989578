<template>
  <div class="container py-5 bg-white">
    <h1>Privacy Policy</h1>

    <p>Effective date: March 21, 2019</p>

    <p>
      Pamedia Services LTD (“us”, “we”, or “our”) operates the
      <a href="https://www.myforexglobal.com">https://www.myforexglobal.com</a>
      website (the “Service”).
    </p>

    <p>
      This page informs you of our policies regarding the collection, use, and
      disclosure of personal data when you use our Service and the choices you
      have associated with that data.
    </p>

    <p>
      We use your data to provide and improve the Service. By using the Service,
      you agree to the collection and use of information in accordance with this
      policy. Unless otherwise defined in this Privacy Policy, terms used in
      this Privacy Policy have the same meanings as in our Terms and Conditions,
      accessible from https://www.myforexglobal.com
    </p>

    <h2>Definitions</h2>

    <ul>
      <li>
        <strong>Service</strong>Service is the https://www.myforexglobal.com
        website operated by Pamedia Services LTD
      </li>
      <li>
        <strong>Personal Data</strong>Personal Data means data about a living
        individual who can be identified from those data (or from those and
        other information either in our possession or likely to come into our
        possession).
      </li>
      <li>
        <strong>Usage Data</strong>Usage Data is data collected automatically
        either generated by the use of the Service or from the Service
        infrastructure itself (for example, the duration of a page visit).
      </li>
      <li>
        <strong>Cookies</strong>Cookies are small pieces of data stored on your
        device (computer or mobile device).
      </li>
      <li>
        <strong>Data Controller</strong>Data Controller means the natural or
        legal person who (either alone or jointly or in common with other
        persons) determines the purposes for which and the manner in which any
        personal information are, or are to be, processed.For the purpose of
        this Privacy Policy, we are a Data Controller of your Personal Data.
      </li>
      <li>
        <strong>Data Processors (or Service Providers)</strong>Data Processor
        (or Service Provider) means any natural or legal person who processes
        the data on behalf of the Data Controller.We may use the services of
        various Service Providers in order to process your data more
        effectively.
      </li>
      <li>
        <strong>Data Subject (or User)</strong>Data Subject is any living
        individual who is using our Service and is the subject of Personal Data.
      </li>
    </ul>

    <h2>Information Collection And Use</h2>

    <p>
      We collect several different types of information for various purposes to
      provide and improve our Service to you.
    </p>

    <h3>Types of Data Collected</h3>

    <h4>Personal Data</h4>

    <p>
      While using our Service, we may ask you to provide us with certain
      personally identifiable information that can be used to contact or
      identify you (“Personal Data”). Personally identifiable information may
      include, but is not limited to:
    </p>

    <ul>
      <li>Email address</li>
      <li>First name and last name</li>
      <li>Phone number</li>
      <li>Address, State, Province, ZIP/Postal code, City</li>
      <li>Cookies and Usage Data</li>
    </ul>

    <p>
      We may use your Personal Data to contact you with newsletters, marketing
      or promotional materials and other information that may be of interest to
      you. You may opt out of receiving any, or all, of these communications
      from us by following the unsubscribe link or instructions provided in any
      email we send or by contacting us.
    </p>

    <h4>Usage Data</h4>

    <p>
      We may also collect information how the Service is accessed and used
      (“Usage Data”). This Usage Data may include information such as your
      computer’s Internet Protocol address (e.g. IP address), browser type,
      browser version, the pages of our Service that you visit, the time and
      date of your visit, the time spent on those pages, unique device
      identifiers and other diagnostic data.
    </p>

    <h4>Tracking &amp; Cookies Data</h4>

    <p>
      We use cookies and similar tracking technologies to track the activity on
      our Service and hold certain information.
    </p>

    <p>
      Cookies are files with small amount of data which may include an anonymous
      unique identifier. Cookies are sent to your browser from a website and
      stored on your device. Tracking technologies also used are beacons, tags,
      and scripts to collect and track information and to improve and analyze
      our Service.
    </p>

    <p>
      You can instruct your browser to refuse all cookies or to indicate when a
      cookie is being sent. However, if you do not accept cookies, you may not
      be able to use some portions of our Service.
    </p>

    <p>Examples of Cookies we use:</p>

    <ul>
      <li>
        <strong>Session Cookies.</strong>&nbsp;We use Session Cookies to operate
        our Service.
      </li>
      <li>
        <strong>Preference Cookies.</strong>&nbsp;We use Preference Cookies to
        remember your preferences and various settings.
      </li>
      <li>
        <strong>Security Cookies.</strong>&nbsp;We use Security Cookies for
        security purposes.
      </li>
      <li>
        <strong>Advertising Cookies.</strong>&nbsp;Advertising Cookies are used
        to serve you with advertisements that may be relevant to you and your
        interests.
      </li>
    </ul>

    <h2>Use of Data</h2>

    <p>Pamedia Services LTD uses the collected data for various purposes:</p>

    <ul>
      <li>To provide and maintain our Service</li>
      <li>To notify you about changes to our Service</li>
      <li>
        To allow you to participate in interactive features of our Service when
        you choose to do so
      </li>
      <li>To provide customer support</li>
      <li>
        To gather analysis or valuable information so that we can improve our
        Service
      </li>
      <li>To monitor the usage of our Service</li>
      <li>To detect, prevent and address technical issues</li>
      <li>
        To provide you with news, special offers and general information about
        other goods, services and events which we offer that are similar to
        those that you have already purchased or enquired about unless you have
        opted not to receive such information
      </li>
    </ul>

    <h2>
      Legal Basis for Processing Personal Data Under General Data Protection
      Regulation (GDPR)
    </h2>

    <p>
      If you are from the European Economic Area (EEA), Pamedia Services LTD
      legal basis for collecting and using the personal information described in
      this Privacy Policy depends on the Personal Data we collect and the
      specific context in which we collect it.
    </p>

    <p>may process your Personal Data because:</p>

    <ul>
      <li>We need to perform a contract with you</li>
      <li>You have given us permission to do so</li>
      <li>
        The processing is in our legitimate interests and it’s not overridden by
        your rights
      </li>
      <li>For payment processing purposes</li>
      <li>To comply with the law</li>
    </ul>

    <h2>Retention of Data</h2>

    <p>
      Pamedia Services LTD will retain your Personal Data only for as long as is
      necessary for the purposes set out in this Privacy Policy. We will retain
      and use your Personal Data to the extent necessary to comply with our
      legal obligations (for example, if we are required to retain your data to
      comply with applicable laws), resolve disputes, and enforce our legal
      agreements and policies.
    </p>

    <p>
      Pamedia Services LTD will also retain Usage Data for internal analysis
      purposes. Usage Data is generally retained for a shorter period of time,
      except when this data is used to strengthen the security or to improve the
      functionality of our Service, or we are legally obligated to retain this
      data for longer time periods.
    </p>

    <h2>Transfer Of Data</h2>

    <p>
      Your information, including Personal Data, may be transferred to — and
      maintained on — computers located outside of your state, province, country
      or other governmental jurisdiction where the data protection laws may
      differ than those from your jurisdiction
    </p>

    <p>
      Your consent to this Privacy Policy followed by your submission of such
      information represents your agreement to that transfer.
    </p>

    <p>
      Pamedia Services LTD will take all steps reasonably necessary to ensure
      that your data is treated securely and in accordance with this Privacy
      Policy and no transfer of your Personal Data will take place to an
      organization or a country unless there are adequate controls in place
      including the security of your data and other personal information.
    </p>

    <h2>Disclosure Of Data</h2>

    <h3>Legal Requirements</h3>

    <p>
      Pamedia Services LTD may disclose your Personal Data in the good faith
      belief that such action is necessary to:
    </p>

    <ul>
      <li>To comply with a legal obligation</li>
      <li>
        To protect and defend the rights or property of Pamedia Services LTD
      </li>
      <li>
        To prevent or investigate possible wrongdoing in connection with the
        Service
      </li>
      <li>
        To protect the personal safety of users of the Service or the public
      </li>
      <li>To protect against legal liability</li>
    </ul>

    <h2>Security Of Data</h2>

    <p>
      The security of your data is important to us, but remember that no method
      of transmission over the Internet, or method of electronic storage is 100%
      secure. While we strive to use commercially acceptable means to protect
      your Personal Data, we cannot guarantee its absolute security.
    </p>

    <h2>“Do Not Track” Signals</h2>

    <p>
      We do not support Do Not Track (“DNT”). Do Not Track is a preference you
      can set in your web browser to inform websites that you do not want to be
      tracked.
    </p>

    <p>
      You can enable or disable Do Not Track by visiting the Preferences or
      Settings page of your web browser.
    </p>

    <h2>
      Your Data Protection Rights Under General Data Protection Regulation
      (GDPR)
    </h2>

    <p>
      If you are a resident of the European Economic Area (EEA), you have
      certain data protection rights. Pamedia Services LTD aims to take
      reasonable steps to allow you to correct, amend, delete, or limit the use
      of your Personal Data.
    </p>

    <p>
      If you wish to be informed what Personal Data we hold about you and if you
      want it to be removed from our systems, please contact us.
    </p>

    <p>
      In certain circumstances, you have the following data protection rights:
    </p>

    <ul>
      <li>
        <strong
          >The right to access, update or to delete the information we have on
          you.</strong
        >&nbsp;Whenever made possible, you can access, update or request
        deletion of your Personal Data directly within your account settings
        section. If you are unable to perform these actions yourself, please
        contact us to assist you.
      </li>
      <li>
        <strong>The right of rectification.</strong>&nbsp;You have the right to
        have your information rectified if that information is inaccurate or
        incomplete.
      </li>
      <li>
        <strong>The right to object.</strong>&nbsp;You have the right to object
        to our processing of your Personal Data.
      </li>
      <li>
        <strong>The right of restriction.</strong>&nbsp;You have the right to
        request that we restrict the processing of your personal information.
      </li>
      <li>
        <strong>The right to data portability.</strong>&nbsp;You have the right
        to be provided with a copy of the information we have on you in a
        structured, machine-readable and commonly used format.
      </li>
      <li>
        <strong>The right to withdraw consent.</strong>&nbsp;You also have the
        right to withdraw your consent at any time where Pamedia Services LTD
        relied on your consent to process your personal information.
      </li>
    </ul>

    <p>
      Please note that we may ask you to verify your identity before responding
      to such requests.
    </p>

    <p>
      You have the right to complain to a Data Protection Authority about our
      collection and use of your Personal Data. For more information, please
      contact your local data protection authority in the European Economic Area
      (EEA).
    </p>

    <h2>Service Providers</h2>

    <p>
      We may employ third party companies and individuals to facilitate our
      Service (“Service Providers”), to provide the Service on our behalf, to
      perform Service-related services or to assist us in analyzing how our
      Service is used.
    </p>

    <p>
      These third parties have access to your Personal Data only to perform
      these tasks on our behalf and are obligated not to disclose or use it for
      any other purpose.
    </p>

    <h3>Analytics</h3>

    <p>
      We may use third-party Service Providers to monitor and analyze the use of
      our Service.
    </p>

    <ul>
      <li>
        <strong>Google Analytics</strong>Google Analytics is a web analytics
        service offered by Google that tracks and reports website traffic.
        Google uses the data collected to track and monitor the use of our
        Service. This data is shared with other Google services. Google may use
        the collected data to contextualize and personalize the ads of its own
        advertising network.You can opt-out of having made your activity on the
        Service available to Google Analytics by installing the Google Analytics
        opt-out browser add-on. The add-on prevents the Google Analytics
        JavaScript (ga.js, analytics.js, and dc.js) from sharing information
        with Google Analytics about visits activity.For more information on the
        privacy practices of Google, please visit the Google Privacy &amp; Terms
        web page:&nbsp;<a
          href="http://www.google.com/intl/en/policies/privacy/"
          target="_blank"
          rel="noopener"
          >http://www.google.com/intl/en/policies/privacy/</a
        >
      </li>
    </ul>

    <h3>Advertising</h3>

    <p>
      We may use third-party Service Providers to show advertisements to you to
      help support and maintain our Service.
    </p>

    <ul>
      <li>
        <strong>Google AdSense &amp; DoubleClick Cookie</strong>Google, as a
        third party vendor, uses cookies to serve ads on our Service. Google’s
        use of the DoubleClick cookie enables it and its partners to serve ads
        to our users based on their visit to our Service or other websites on
        the Internet.You may opt out of the use of the DoubleClick Cookie for
        interest-based advertising by visiting the Google Ads Settings web
        page:&nbsp;<a
          href="http://www.google.com/ads/preferences/"
          target="_blank"
          rel="noopener"
          >http://www.google.com/ads/preferences/</a
        >
      </li>
    </ul>

    <h3>Behavioral Remarketing</h3>

    <p>
      Pamedia Services LTD uses remarketing services to advertise on third party
      websites to you after you visited our Service. We and our third-party
      vendors use cookies to inform, optimize and serve ads based on your past
      visits to our Service.
    </p>

    <ul>
      <li>
        <strong>Google AdWords</strong>Google AdWords remarketing service is
        provided by Google Inc.You can opt-out of Google Analytics for Display
        Advertising and customize the Google Display Network ads by visiting the
        Google Ads Settings page:&nbsp;<a
          href="http://www.google.com/settings/ads"
          target="_blank"
          rel="noopener"
          >http://www.google.com/settings/ads</a
        >Google also recommends installing the Google Analytics Opt-out Browser
        Add-on –&nbsp;<a
          href="https://tools.google.com/dlpage/gaoptout"
          target="_blank"
          rel="noopener"
          >https://tools.google.com/dlpage/gaoptout</a
        >&nbsp;– for your web browser. Google Analytics Opt-out Browser Add-on
        provides visitors with the ability to prevent their data from being
        collected and used by Google Analytics.For more information on the
        privacy practices of Google, please visit the Google Privacy &amp; Terms
        web page:&nbsp;<a
          href="http://www.google.com/intl/en/policies/privacy/"
          target="_blank"
          rel="noopener"
          >http://www.google.com/intl/en/policies/privacy/</a
        >
      </li>
    </ul>

    <h3>Payments</h3>

    <p>
      We may provide paid products and/or services within the Service. In that
      case, we use third-party services for payment processing (e.g. payment
      processors).
    </p>

    <p>
      We will not store or collect your payment card details. That information
      is provided directly to our third-party payment processors whose use of
      your personal information is governed by their Privacy Policy. These
      payment processors adhere to the standards set by PCI-DSS as managed by
      the PCI Security Standards Council, which is a joint effort of brands like
      Visa, Mastercard, American Express and Discover. PCI-DSS requirements help
      ensure the secure handling of payment information.
    </p>

    <p>The payment processors we work with are:</p>

    <ul>
      <li>
        <strong>Google Play In-App Payments</strong>Their Privacy Policy can be
        viewed at&nbsp;<a
          href="https://www.google.com/policies/privacy/"
          target="_blank"
          rel="noopener"
          >https://www.google.com/policies/privacy/</a
        >
      </li>
      <li>
        <strong>Stripe</strong>Their Privacy Policy can be viewed at&nbsp;<a
          href="https://stripe.com/us/privacy"
          target="_blank"
          rel="noopener"
          >https://stripe.com/us/privacy</a
        >
      </li>
      <li>
        <strong>PayPal or Braintree</strong>Their Privacy Policy can be viewed
        at&nbsp;<a
          href="https://www.paypal.com/webapps/mpp/ua/privacy-full"
          target="_blank"
          rel="noopener"
          >https://www.paypal.com/webapps/mpp/ua/privacy-full</a
        >
      </li>
    </ul>

    <h2>Links To Other Sites</h2>

    <p>
      Our Service may contain links to other sites that are not operated by us.
      If you click on a third party link, you will be directed to that third
      party’s site. We strongly advise you to review the Privacy Policy of every
      site you visit.
    </p>

    <p>
      We have no control over and assume no responsibility for the content,
      privacy policies or practices of any third party sites or services.
    </p>

    <h2>Children’s Privacy</h2>

    <p>Our Service does not address anyone under the age of 18 (“Children”).</p>

    <p>
      We do not knowingly collect personally identifiable information from
      anyone under the age of 18. If you are a parent or guardian and you are
      aware that your Children has provided us with Personal Data, please
      contact us. If we become aware that we have collected Personal Data from
      children without verification of parental consent, we take steps to remove
      that information from our servers.
    </p>

    <h2>Changes To This Privacy Policy</h2>

    <p>
      We may update our Privacy Policy from time to time. We will notify you of
      any changes by posting the new Privacy Policy on this page.
    </p>

    <p>
      We will let you know via email and/or a prominent notice on our Service,
      prior to the change becoming effective and update the “effective date” at
      the top of this Privacy Policy.
    </p>

    <p>
      You are advised to review this Privacy Policy periodically for any
      changes. Changes to this Privacy Policy are effective when they are posted
      on this page.
    </p>

    <h2>Contact Us</h2>

    <p>
      If you have any questions about this Privacy Policy, please contact us:
    </p>
  </div>
</template>

<script>
export default {};
</script>
